<template>
  <div>
    <div class="card">
      <div class="card-body">
        <table class="table datatable" ref="datatable">
          <thead>
            <tr>
              <th>{{ $t("FORM.ID") }}</th>
              <th>{{ $t("COMPANY.TITLE") }}</th>
              <th>{{ $t("FORM.EMAIL") }}</th>
              <th>{{ $t("COMPANY.QUICK_SALE") }}</th>
              <th>{{ $t("LIST.CREATED_AT") }}</th>
              <th class="px-0"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="company in companies" :key="company.id">
              <td>{{ company.id }}</td>
              <td>{{ company.title }}</td>
              <td>{{ company.email }}</td>
              <td>
                <span
                  class="align-items-center justify-content-center commission-row"
                >
                  <b-icon
                    icon="check"
                    font-scale="2.5"
                    class="common-icon-color"
                    variant="success"
                    v-if="company.hasQuickSale"
                  ></b-icon>
                  <b-icon
                    icon="x"
                    v-else
                    font-scale="2.5"
                    class="common-icon-color"
                  ></b-icon>
                </span>
              </td>

              <td>
                {{ company.createdAt | moment("DD.MM.YYYY, HH:mm") }}
              </td>
              <td class="px-0 text-center">
                <button
                  class="edit-button"
                  @click.stop.prevent="undeleteCompany(company.id)"
                >
                  <b-icon
                    icon="arrow-counterclockwise"
                    variant="primary"
                    font-scale="1.3"
                  ></b-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      companies: {}
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kurumsal" },
      { title: "Firmalar" },
      { title: "Silinmiş Firmalar" }
    ]);
  },
  methods: {
    ...mapActions({
      getDeletedCompanies: "company/GET_DELETED_COMPANIES",
      undeleteSelectedCompany: "company/UNDELETE_COMPANY"
    }),
    undeleteCompany(id) {
      this.undeleteSelectedCompany(id).then(() => {
        this.$router.replace({ name: "company-companies" });
      });
    }
  },
  async created() {
    this.companies = await this.getDeletedCompanies();
  }
};
</script>

<style lang="scss" scoped>
.edit-button {
  margin: 0;
  background-color: transparent;
  border: none;
}

.commission-row {
  width: 32%;
}

.table td {
  padding: 0.5rem;
  vertical-align: middle;
}
</style>
